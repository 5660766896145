import React from 'react';

function MainContent() {
  return (
    <main className="flex-grow flex flex-col justify-center items-center px-4 py-16 relative z-10">
      <div className="container mx-auto px-4 py-12">
        <header className="text-center mb-12">
          <h1 className="text-2xl font-semibold mb-6">
            Welcome to GPTCONSEIL, based in Paris, where we bring deep technical and strategic vision to assist you in redefining your future business processes.
          </h1>
        </header>
        <section className="mb-16">
          <p className="text-center text-lg mb-8">
            With experience in Vision AI, Speech AI, AI infrastructure, AI for document processing and customer support, actively 
            tracking research in the field, we bring an immediate strategic impact on your business in an exponential growth area.
            As a trusted AI partner, we provide assessment, strategic planning, data strategy, pilot projects, integration and change management. 
          </p>
        </section>
        <section className="mb-16">
          <h2 className="text-center text-sm font-semibold uppercase tracking-wider mb-4">
            Auditing and acting on your current processes to bring AI efficiency
          </h2>
          <div className="text-center">
            <h3 className="text-6xl font-bold">
              human to &nbsp;
              <span className="text-[#ff00ff]">
                HYBRID
              </span>
            </h3>
          </div>
        </section>
        <section>
          <h2 className="text-center text-sm font-semibold uppercase tracking-wider mb-8">
            Past projects
          </h2>
          <div className="border-t border-gray-700 pt-8">
            <div className="flex justify-between items-center">
              <button className="text-2xl">
                <i className="fas fa-chevron-left"></i>
              </button>
              <div className="flex space-x-8 items-center">
                <img alt="IMT" className="h-8" src="/assets/logo-imt.png" />
                <img alt="HomeGenie" className="h-8" src="/assets/ckapital.png" />
                <img alt="HomeGenie" className="h-8" src="/assets/homegenie.png" />
                <img alt="BoursoBank" className="h-8" src="/assets/bourso.png" />
                <img alt="ENGIE" className="h-8" src="/assets/engie.png" />
              </div>
              <button className="text-2xl">
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </section>
        <br/>
        <br/>
        <br/>
        <section>
          <h2 className="text-center text-sm font-semibold uppercase tracking-wider mb-8">
            Partners
          </h2>
          <div className="border-t border-gray-700 pt-8">
            <div className="flex justify-between items-center">
              <button className="text-2xl">
                <i className="fas fa-chevron-left"></i>
              </button>
              <div className="flex space-x-8 items-center">
                <img alt="CRIANN" className="h-8" src="/assets/logo_CRIANN.webp" />
              </div>
              <button className="text-2xl">
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default MainContent;