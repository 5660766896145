import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import MainContent from './components/MainContent';
import TechnicalServices from './components/TechnicalServices';
import BusinessServices from './components/BusinessServices';
import CookiePolicy from './components/CookiePolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App bg-[#0a0a1e] text-white min-h-screen flex flex-col">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <MainContent />
            </>
          } />
          <Route path="/technical" element={<TechnicalServices />} />
          <Route path="/business" element={<BusinessServices />} />
          <Route path="/lab" element={<div className="flex-grow flex items-center justify-center"><h1 className="text-4xl">Lab</h1></div>} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
