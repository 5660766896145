import React from 'react';
import { useNavigate } from 'react-router-dom';

function Hero() {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 flex-grow relative z-10">
      <div className="max-w-3xl mt-16">
        <h2 className="text-xl font-semibold mb-2">Strategize your</h2>
        <h1 className="text-6xl font-bold mb-8">Artificial<br />Intelligence</h1>
        <div className="flex space-x-4 mb-16">
          <button onClick={() => navigate('/technical')} className="bg-[#e455e8] text-white px-6 py-3 rounded-full font-semibold">TECHNICAL SERVICES</button>
          <button onClick={() => navigate('/business')} className="bg-transparent border border-white text-white px-6 py-3 rounded-full font-semibold">BUSINESS SERVICES</button>
        </div>
      </div>
      <div className="absolute top-0 right-0 w-1/2 h-screen overflow-hidden" style={{zIndex: 1}}>
        <div className="w-full h-full bg-gradient-to-br from-[#e455e8] via-[#ff6b6b] to-[#ffa26b] transform rotate-45 translate-x-1/2 -translate-y-1/4"></div>
      </div>
    </div>
  );
}

export default Hero;