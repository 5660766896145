import React from 'react';

function TechnicalServices() {
  return (
    <main className="flex-grow container mx-auto px-4 py-8">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-white text-3xl font-light mb-2">zero to</h2>
            <h1 className="text-[#ff6b35] text-6xl font-bold">FUTURE</h1>
          </div>
          <div className="w-1/2">
            <img alt="AI leading the path" width="200" height="200" src="/assets/future.png" style={{objectFit: 'contain'}}/>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-12">
       <center>YOUR AI HUMAN ASSISTANTS</center> 
      </h1>
      <div className="flex flex-col md:flex-row-reverse items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:ml-8">
          <img alt="Another abstract AI visualization" className="w-full rounded-lg" height="150" src="/assets/server.png" width="240"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            Access to an high grade superserver at preferred price for finetuning
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>GPUs: </strong>  11 nodes x 8 GPU Nvidia A100 80 GB SXM4. Total 88 GPU. </li>
            <li><strong>CPUs:</strong>  124 parallel computing nodes AMD Genoa– 2x96 cores- 768 GB RAM DDR5 Total 23 808 cœurs@2.4 GHz</li>
            <li>Optimizing batches and python packages for pyhton 3.10.10, tensorlow 2.11, pythorch 2.0</li>
          </ul>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="mb-16 flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:mr-8">
          <img alt="Abstract visualization of AI" className="w-full rounded-lg" height="200" src="/assets/STEP2.png" width="300"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            LLM Optimization and Evaluation
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>Advanced Prompting Strategy:</strong> Mastery of 32 techniques to optimize interactions with AI models.</li>
            <li><strong>LLM Evaluation with Appropriate Metrics:</strong> Rigorous analysis and comparison of language models using relevant metrics.</li>
            <li><strong>LLM Performance and Comparison:</strong> Performance benchmarking with tools like LLM-as-a-Judge (e.g., Vicuna).</li>
            <li><strong>Performance Comparison on Coding Tasks:</strong> Evaluation of model performance with Gemma 2B, 9B, and 27B.</li>
            <li><strong>Multiple LLM Collaboration:</strong> Implementing collaboration strategies between LLMs with Route LLMs.</li>
            <li><strong>Data Extraction from Documents with LLM:</strong> Utilizing Sparrow for document data extraction.</li>
            <li><strong>Web-Based Information Search with LLM:</strong> Optimizing information retrieval via language models.</li>
            <li><strong>LLM Environment for Coding:</strong> Coding assistance using tools such as Code Interpreter, Claude Dev, Cursor, LlamaCoder, Codestral, and CodeGPT.</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:ml-8">
          <img alt="Another abstract AI visualization" className="w-full rounded-lg" height="200" src="/assets/STEP1.png" width="300"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            AI Training and Methodologies
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>AI Training Methodologies:</strong> Application of cutting-edge strategies to enhance model efficiency.</li>
            <li><strong>Distributed AI Training:</strong> Implementation of distributed training methodologies for improved performance.</li>
            <li><strong>Model Training: </strong> Fine-tuning, model distillation, and comparison between prompting and fine-tuning.</li>
            <li><strong>Open Source Frameworks vs. Closed Source: </strong> Guidance on selecting and implementing the right frameworks for your needs.</li>
            <li><strong>Cost Saving Strategies: </strong> LLM Gateways, prompt caching, RouteLLM, on promise vs cloud providers, GPU spot booking</li>
          </ul>
        </div>
      </div>
      <div className="mb-16 flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:mr-8">
          <img alt="Abstract visualization of AI" className="w-full rounded-lg" height="200" src="/assets/STEP3.png" width="300"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            Image and Vision AI
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>Visual and Image Understanding:</strong> Expertise in visual interpretation through specialized AI models.</li>
            <li><strong>Image Generation:</strong> Using ComfyUI, IPAdapter, ControlNet, and fine-tuning on SDXL, SD3, and SD2 for image generation.</li>
            <li><strong>Vision Models for Document Understanding:</strong> Implementing ColPali, CLIP for document analysis through vision models. Advanced hybrid search (text and image) search techniques.</li>
            <li><strong>Open-Source Multimodal LLM:</strong> Developing and integrating multimodal LLMs such as Anole.</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:ml-8">
          <img alt="Another abstract AI visualization" className="w-full rounded-lg" height="200" src="/assets/STEP4.png" width="300"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            Coding with AI
          </h2>
          <p className="text-sm md:text-base leading-relaxed">
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>Benchmarked vscode tools:</strong> Claude Dev, Cursor.com, CodeGenius, OpenDevin, Github Copilot, GPTEngineer, OPenInterpreter, Codium, OpenAI Codex </li>
            <li><strong>Benchmarked LLMs:</strong> DeepSeek V2, Claude Sonnet 3.5, Codestral, GPT 4o</li>
            <li><strong>Dev team 2days training: </strong> <a href="/assets/IACODER.pdf" className="text-[#b366ff] hover:underline">IA First Coder</a></li>
          </ul>
          </p>
        </div>
      </div>
    </main>
  );
}

export default TechnicalServices;