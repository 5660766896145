import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    emailjs.init("5ABqGwNRbqMYC9Pdu"); // Initialize EmailJS
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
      'service_c8h6twf',
      'template_anoyc1b',
      {
        from_name: formData.name,
        from_email: formData.email,
        subject: formData.subject,
        message: formData.message,
        to_email: 'contact@gptconseil.com'
      },
      '5ABqGwNRbqMYC9Pdu'
    )
    .then((response) => {
      console.log('Email sent successfully:', response);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      console.error('Error details:', JSON.stringify(error, null, 2));
      setSubmitStatus('error');
    });
  };

  return (
    <div className="container mx-auto px-4 py-8 flex">
      <div className="w-1/2 pr-8">
        <h3 className="text-[#ff00ff] text-sm mb-8">WANNA CHAT?</h3>
        <h1 className="text-6xl font-bold mb-4">zero to AI</h1>
        <h2 className="text-3xl">Let's Discuss Your AI Vision</h2>
      </div>
      <div className="w-1/2">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm mb-2">Name <span className="text-red-500">*</span></label>
            <input 
              type="text" 
              id="name" 
              name="name"
              value={formData.name}
              onChange={handleChange}
              required 
              className="w-full bg-transparent border border-gray-600 rounded p-2"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm mb-2">Email Address <span className="text-red-500">*</span></label>
            <input 
              type="email" 
              id="email" 
              name="email"
              value={formData.email}
              onChange={handleChange}
              required 
              className="w-full bg-transparent border border-gray-600 rounded p-2"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="block text-sm mb-2">Subject</label>
            <input 
              type="text" 
              id="subject" 
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full bg-transparent border border-gray-600 rounded p-2"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm mb-2">Message <span className="text-red-500">*</span></label>
            <textarea 
              id="message" 
              name="message"
              value={formData.message}
              onChange={handleChange}
              required 
              rows="6" 
              className="w-full bg-transparent border border-gray-600 rounded p-2"
            ></textarea>
          </div>
          <button type="submit" className="bg-[#ff00ff] text-white px-6 py-2 rounded">Send Message</button>
        </form>
        {submitStatus === 'success' && (
          <p className="mt-4 text-green-500">Message sent successfully!</p>
        )}
        {submitStatus === 'error' && (
          <p className="mt-4 text-red-500">Error sending message. Please try again.</p>
        )}
        <p className="mt-4 text-sm">or send an e-mail to <a href="mailto:contact@gptconseil.com" className="text-[#ff00ff]">contact@gptconseil.com</a></p>
      </div>
    </div>
  );
}

export default Contact;