import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-8 py-16">
      <h1 className="text-4xl font-bold mb-4">GPTCONSEIL Privacy Policy</h1>
      <p className="text-sm mb-8">Effective Date: 06/10/2023</p>

      <ol className="space-y-6">
        <li>
          <h2 className="text-xl font-semibold mb-2">1. Information Collection</h2>
          <ol className="list-alpha space-y-4">
            <li>
              <h3 className="font-semibold">a. Information You Provide Us Directly:</h3>
              <p className="text-sm">When you interact with us through our Contact Form, we collect personal information such as your real name, company, and email address. We retain the messages you send to us for operational purposes and to communicate effectively with you.</p>
            </li>
            <li>
              <h3 className="font-semibold">b. Analytics Information:</h3>
              <p className="text-sm">We collect analytics data directly or through third-party services to measure website traffic and usage trends. This information, sent by your browser or device, includes the pages you visit and helps us improve our website. We use this data in an aggregated, non-identifiable form.</p>
            </li>
            <li>
              <h3 className="font-semibold">c. Cookies Information:</h3>
              <p className="text-sm">Our website uses cookies to enhance your experience, as detailed in our Cookie Policy. Cookies are small text files sent to your browser for record-keeping purposes. They help us personalize content and monitor website effectiveness. You can find more information about how we use cookies and your choices regarding them in our Cookie Policy.</p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-xl font-semibold mb-2">2. Use of Information</h2>
          <p className="text-sm mb-2">We use the information collected to:</p>
          <ul className="list-disc list-inside text-sm space-y-1">
            <li>Operate our website and communicate with you.</li>
            <li>Enhance website performance and user experience.</li>
            <li>Send newsletters, insights, and updates. You can opt out of receiving these communications at any time.</li>
          </ul>
        </li>
        <li>
          <h2 className="text-xl font-semibold mb-2">3. Sharing of Information</h2>
          <p className="text-sm">We do not rent or sell your information to third parties without your consent. Our Privacy Policy does not cover third-party activities; please consult their policies for more information.</p>
        </li>
        <li>
          <h2 className="text-xl font-semibold mb-2">4. Data Security and Protection</h2>
          <p className="text-sm">We strive to protect your information with commercially reasonable safeguards. However, no security system is impenetrable, and we cannot guarantee the absolute security of your data. In case of a data breach, we will take necessary steps as per applicable laws and regulations.</p>
        </li>
        <li>
          <h2 className="text-xl font-semibold mb-2">5. Your Choices and Control Over Information</h2>
          <p className="text-sm">You have control over your personal information. You can opt out of promotional emails and manage cookie settings through your browser or device. Please note that disabling cookies may affect the functionality of our website.</p>
        </li>
        <li>
          <h2 className="text-xl font-semibold mb-2">6. Policy Updates</h2>
          <p className="text-sm">We may update this policy periodically. We encourage you to review it regularly to stay informed about how we protect your information.</p>
        </li>
      </ol>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
        <p className="text-sm">For questions about this Privacy Policy, please reach out to us through our Contact Form.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;