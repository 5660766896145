import React from 'react';

function BusinessServices() {
  return (
    <div className="container mx-auto px-4 py-16">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center">
          <img alt="Laptop with AI chatbot icon" className="mx-auto mb-4" height="100" src="/assets/aicontent.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Generative AI for Content
          </h3>
          <p className="text-sm text-gray-300">
            Solutions in text, image, and video generation, for businesses seeking innovative content creation. <br/>
            AI-based retrieval systems for images, videos and text, or hybrid.
          </p>
        </div>
        <div className="text-center">
          <img alt="Computer monitor with graph and gear icon" className="mx-auto mb-4" height="100" src="/assets/aibi.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Business intelligence: Forecasting and automatic dashboards
          </h3>
          <p className="text-sm text-gray-300">
            AI extends to forecasting time series problems, especially for retail and e-commerce sectors.<br/>
            AI builds analytics dashboards directly from datasources (databases, excel) by natural language queries.
          </p>
        </div>
        <div className="text-center">
          <img alt="Cloud with database and network icons" className="mx-auto mb-4" height="100" src="/assets/aidata.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Optimize your Data Infrastructure
          </h3>
          <p className="text-sm text-gray-300">
            Create bridges between documents, databases, dashboards with AI. Use AI to query your document store with natural language.<br/>
            Go beyond OCR with AI Vision and extract graphs and formulas from any files.
          </p>
        </div>
        <div className="text-center">
          <img alt="Computer monitor with shopping cart icon" className="mx-auto mb-4" height="100" src="/assets/aiecommerce.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            AI-Powered Recommendation Systems
          </h3>
          <p className="text-sm text-gray-300">
            Product recommendation, people matching, HR candidate to job matching.
          </p>
        </div>
        <div className="text-center">
          <img alt="AI chip icon" className="mx-auto mb-4" height="100" src="/assets/aimarketing.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Marketing Intelligence - From Churn Prediction to Causal Models
          </h3>
          <p className="text-sm text-gray-300">
            Get insights into customer behavior with AI-based churn prediction, causal models.<br/>
            Customize your customer message with custom made content through their profile/website AI analysis.
          </p>
        </div>
        <div className="text-center">
          <img alt="Plant growing from hand icon" className="mx-auto mb-4" height="100" src="/assets/aistartup.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            AI-Driven Startups
          </h3>
          <p className="text-sm text-gray-300">
            Advising early-stage startups whose products are rooted in AI.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BusinessServices;